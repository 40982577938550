export const WP_MODULES = {
  HEADER_BLOG_SINGLE: { id: "1", wpName: "headerBlogSingle", wpFunName: "headerBlogSingle" },
  HEADER_BLOG_BASIC: { id: "2", wpName: "headerBasic", wpFunName: "headerBasic" },
  BODY_TEXT: { id: "3", wpName: "bodytext", wpFunName: "bodyText" },
  FEATURED_BLOGS: { id: "4", wpName: "featuredBlogs", wpFunName: "blogFeatured" },
  CTA_SMALL: { id: "5", wpName: "ctaSmall", wpFunName: "ctaSmall" },
  NAV_BAR: { id: "6", wpName: "navBar", wpFunName: "navBar" },
  FOOTER: { id: "7", wpName: "footer", wpFunName: "footer" },
  FEATURED_MERCHANTS: { id: "8", wpName: "featuredStores", wpFunName: "featuredStores" },
  FEATURED_DEALS: { id: "9", wpName: "featuredDeals", wpFunName: "featuredDeals" },
  FEATURED_CATEGORIES: { id: "10", wpName: "featuredCategories", wpFunName: "featuredCategories" },
  HEADER_HOMEPAGE: { id: "11", wpName: "headerHomepage", wpFunName: "homepageHeader" },
  HEADER_SMALL_IMAGE: { id: "12", wpName: "headerSmallImage", wpFunName: "smallImageHeader" },
  HEADER_INFO: { id: "13", wpName: "headerInformative", wpFunName: "informativeHeader" },
  BANNER: { id: "14", wpName: "banner", wpFunName: "banner" },
  BLOG_OVERVIEW: { id: "15", wpName: "blogOverview", wpFunName: "blogOverview" },
  MERCHANT_OVERVIEW: { id: "16", wpName: "merchantOverview", wpFunName: "merchantOverview" },
  DEAL_OVERVIEW: { id: "17", wpName: "dealOverview", wpFunName: "dealOverview" },
  SINGLE_STORE: { id: "18", wpName: "singleStore", wpFunName: "singleStore" },
  LANDING_PAGE: { id: "19", wpName: "LandingPageModule", wpFunName: "landingPage" },
  LINK_BOXES: { id: "20", wpName: "linkBoxModule", wpFunName: "linkBoxes" },
  MERCHANT_LINK_LIST: { id: "21", wpName: "merchantLinks", wpFunName: "merchantLinks" },
  HEADER_CTA: { id: "22", wpName: "headerHomepageNewDesign", wpFunName: "headerHomepageNewDesign" },
  USPS: { id: "23", wpName: "uspModule", wpFunName: "uspModule" },
  AUTHORITY: { id: "24", wpName: "authorityModule", wpFunName: "authorityModule" },
  JSON_SCHEMA: { id: "25", wpName: "jsonSchema", wpFunName: "jsonSchema" },
  NEW_NAV_BAR: { id: "26", wpName: "newNavBar", wpFunName: "newNavBar" },
  FEATURED_DEALS_SEARCH: { id: "27", wpName: "featuredDealsSearch", wpFunName: "featuredDeals" },
  WHEN_IS: { id: "28", wpName: "whenIs", wpFunName: "whenIs" },
  CATEGORY_BLOCKS: { id: "29", wpName: "categoryBlocks", wpFunName: "categoryBlocks" },
  SINGLE_PRODUCT: { id: "30", wpName: "singleProduct", wpFunName: "singleProduct" }
};
