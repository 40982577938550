import { SearchApi } from "@app/bf-api";
import { INavbarSearchCards, setNavbarPages, setNavbarSearchItems, setNavbarSearchText } from "../reducers/search";
import { pagesWordPressApiGet } from "@app/api/wp-page-fetcher/wordpress-api/base-wp-api";
import { ISearchPageData } from "@app/api/core/search-page-data";
import { mapMerchantsToFeaturedMerchants } from "@app/api/wp-page-fetcher/module-selector/module-mappers";

import { AppThunk } from "../store";

const SKIP = 0;

export const searchItems = (searchValue: string, platformId: number, limit: number): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch(setNavbarSearchText(searchValue));

    if (!searchValue) {
      dispatch(setNavbarSearchItems({ deals: [], merchants: [] }));

      return;
    }

    const api = new SearchApi();
    const resultFromApi = await api.searchGet(
      limit,
      SKIP,
      platformId,
      undefined,
      undefined,
      searchValue || undefined,
      undefined
    );

    const result: INavbarSearchCards = {
      merchants: mapMerchantsToFeaturedMerchants(resultFromApi?.merchants || []),
      deals: resultFromApi?.deals || []
    };

    dispatch(setNavbarSearchItems(result));
  } catch (error) {
    // tslint:disable-next-line: no-unused-expression no-unsafe-any
    // new FatalError(error.name, error.message, error.stack);
  }
};

export const searchPages = (searchValue: string, platformId: number, limit: number): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const pageData: ISearchPageData[] = [];
    dispatch(setNavbarSearchText(searchValue));

    if (!searchValue) {
      dispatch(setNavbarPages([]));

      return;
    }

    const result = (await pagesWordPressApiGet(platformId, searchValue, limit, SKIP)) as any;

    result.data.forEach((item: any) => {
      pageData.push({
        icon: {
          alt: item?.data?.modules?.[0]?.informativeHeader?.icon?.title || "",
          src: item?.data?.modules?.[0]?.informativeHeader?.icon?.url || ""
        },
        permalink: item.data.permalink,
        title: item.data.post.post_title
      });
    });

    dispatch(setNavbarPages(pageData));
  } catch (error) {
    // tslint:disable-next-line: no-unused-expression no-unsafe-any
    // new FatalError(error.name, error.message, error.stack);
  }
};

export const navbarSearchThunks = {
  searchItems
};
