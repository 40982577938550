import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { WordPressPostModule } from "@app/api/modules/wordpress-module/wordpress-module";
import { ProductSingleModule } from "@app/api/modules/product-single/product-single.module";
import { checkSpecialSinglePage } from "@app/api/wp-page-fetcher/utils/check-special-single-page";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { getLastArrayItem } from "@app/util/get-last-array-item";
import { singleProductGetApi } from "@app/middleware/api/single-product-get.api";
import { OutProductExtendedViewDTO } from "@app/bf-api";
import { setSelectedProduct } from "@app/redux/reducers/product";

import { IWPProductSingleModuleFull } from "./product-single-module.interface";
import { breadcrumbComponentMapper } from "../../component-mappers";

export interface IProductSingleMapper extends IModuleFetcher {
  module: IWPProductSingleModuleFull;
}

export const productSingleModuleMapper = async (
  props: IProductSingleMapper
): Promise<ProductSingleModule | WordPressPostModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      productPageUrlName,
      splittedUrl,
      pageData,
      pageUrl
    } = props;

    if (!data || !checkSpecialSinglePage(productPageUrlName, splittedUrl))
      return moduleMapperThrowMessage("No data found in productSingleModuleMapper");

    const product = await setSingleProductInStore(props);

    if (product === 302) {
      return { id: "30", name: "ProductSingleModule", status: 302 };
    }

    if (!product) return moduleMapperThrowMessage("Cannot get single product in productSingleModuleMapper");

    const breadcrumbProps = await breadcrumbComponentMapper({
      platformId,
      pageUrl,
      variant: "dark",
      title: pageData.meta?.breadcrumbTitle
    });

    return {
      id: "30",
      name: "ProductSingleModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      product,
      compareTitle: data.compareTitle,
      noResultText: data.noResultText,
      breadcrumbProps
    };
  } catch (e) {
    Logger.logError(e, "productSingleModuleMapper went wrong");

    return undefined;
  }
};

export const setSingleProductInStore = async (
  props: IProductSingleMapper
): Promise<OutProductExtendedViewDTO | undefined | 302> => {
  const { splittedUrl, platformId, res, dispatch } = props;

  if (!splittedUrl) return undefined;

  try {
    const slug = getLastArrayItem(splittedUrl);
    const product = await singleProductGetApi(slug, platformId);

    if (!product) return moduleMapperThrowMessage("Cannot get single product");

    if (product === 302) return 302;

    if (res) {
      res.locals.store.dispatch(setSelectedProduct(product));
    }
    if (dispatch) {
      dispatch(setSelectedProduct(product));
    }

    return product;
  } catch (e) {
    Logger.logError(e, "Error in: getSingleProduct");

    return undefined;
  }
};
