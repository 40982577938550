import clsx from "clsx";
import React from "react";

import IcnChevronRight from "@assets/icons/chevron-right-sharp.svg";
import { PlatformType } from "@app/constants/platform";
import { formatMessage } from "@app/translations/intl";
import { getOptimizedImageUrl } from "@app/util/image-optimization";
import { Icon } from "@app/components/icon/icon";
import { RelAttributes } from "@app/constants";

import { ImageComponent } from "../image/image.component";
import { CurrencyComponent } from "../currency/currency.component";
import { ClickableComponent } from "../clickable";
import { IOutFeaturedDealDTO } from "./deal-card.interface";
import { FeaturedFilterLabel } from "../featured-filter-label/featured-filter-label";
import styles from "./deal-card-component.module.scss";

export const SHOW_DISCOUNT_GTI = 20;

export interface IDealCardComponentProps {
  deal: IOutFeaturedDealDTO;
  responsive?: boolean;
  viewType?: number;
  isSponsored?: boolean;
  platformId: number;
  variant?: "normal" | "mobile-search";
}

export const NewDealCardComponent = (props: IDealCardComponentProps) => {
  const { discount, image, merchant, name, price, beforePrice, featuredFilter } = props.deal;

  const viewType = props.viewType ? props.viewType : 100;
  const dealCardVariantClass = props.responsive ? styles.responsive : viewType < 2 ? styles.responsive : "";

  const showBeforePrice = !!beforePrice && !!price && price - beforePrice !== 0;
  const showDiscount = showBeforePrice && (discount || 0) >= SHOW_DISCOUNT_GTI;

  const cardBorderStyles = clsx(
    (props.variant === "normal" || !props.variant) && styles.normal,
    props.variant === "mobile-search" && styles.mobileSearch
  );
  const urlAddition = formatMessage({ id: "global.urlAddition" });

  const outMerchantLink = urlAddition
    ? `/out/store/${urlAddition}/${merchant?.slug}/`
    : `/out/store/${merchant?.slug}/`;

  const outURL = props.deal.merchant?.slug
    ? `/out/offer/${props.deal.merchant.slug}/${props.deal.id}/`
    : `/out/offer/${props.deal.id}/`;

  return (
    <div className={clsx(styles.dealCard, cardBorderStyles, dealCardVariantClass)}>
      <a rel="sponsored" target="_blank" href={outURL} className={styles.leftSection}>
        {featuredFilter?.name && (
          <FeaturedFilterLabel label={featuredFilter.label} text={featuredFilter.name} corners="left" />
        )}
        {props.isSponsored && (
          <div className={styles.sponsored}>{formatMessage({ id: "dealCard.sponsoredLabel" })}</div>
        )}
        <div className={styles.image}>
          <ImageComponent
            src={getOptimizedImageUrl({
              url: image?.url,
              height: 120
            })}
            alt={image?.alt}
            title={image?.title}
            isBlocking
          />

          {showDiscount && (
            <div className={styles.discountLabel} style={{ width: !beforePrice && !price ? "100%" : "auto" }}>
              <span className={styles.discount}>{`-${discount}%`}</span>
            </div>
          )}
        </div>
        <div className={styles.price}>
          {!!price && (
            <div className={styles.priceBox}>
              {!!beforePrice && showBeforePrice && (
                <div className={styles.priceOld}>
                  <CurrencyComponent value={beforePrice} variant="old" />
                </div>
              )}
              {!!price && (
                <div className={clsx(styles.priceNew, props.platformId === PlatformType.FRENCH && styles.redPrice)}>
                  <CurrencyComponent value={price} variant="new" />
                </div>
              )}
            </div>
          )}
        </div>
      </a>
      <div className={styles.rightSection}>
        <a rel="sponsored" target="_blank" href={outURL} className={styles.rightSectionLink}>
          <div className={styles.content}>
            <div className={styles.subtitleWrapper}>
              {merchant?.name && (
                <span title={merchant.name} className={styles.merchantName}>
                  {merchant.name}
                </span>
              )}
            </div>

            <div className={styles.titleWrapper}>
              <span className={styles.title}>
                <h4 title={name}>{name}</h4>
              </span>
            </div>
          </div>

          <div className={styles.action}>
            <ClickableComponent
              title={formatMessage({ id: "dealCard.button.toCampaignPageOut" })}
              variant="primary-new"
              iconStyle="filled"
              iconRight={IcnChevronRight}
              iconSize="12px"
              fullWidth
              isNonClickable
              relAttribute={RelAttributes.SPONSORED}
            />
          </div>
        </a>
        {merchant?.slug && (
          <a className={styles.outMerchantLink} target="_blank" rel="sponsored" href={outMerchantLink}>
            <span className={styles.merchantLinkText}>{formatMessage({ id: "dealCard.showAllMerchantDeals" })}</span>
            <Icon icon={IcnChevronRight} fillColor="#999999" size="9px" />
          </a>
        )}
      </div>
    </div>
  );
};
