export interface IConfig {
  API_URL: string;
  GOOGLE_AD_SENSE_CLIENT: string;
}

export const isDevelopment = () => true;

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    appConfig: any;
  }
}

const basePath =
  process.env.NODE_ENV !== "production"
    ? "https://test.blackfridaynederland.nl/api/"
    : `https://${process.env.API_URL}`;

export const GET_CONFIG: IConfig = {
  API_URL: basePath.replace(/\/+$/, ""),
  GOOGLE_AD_SENSE_CLIENT: "ca-pub-2496803522330859"
};
