import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import IcnChevronRight from "@assets/icons/chevron-right-sharp.svg";
import { formatMessage } from "@app/translations/intl";
import { getOptimizedImageUrl } from "@app/util/image-optimization";
import { RelAttributes } from "@app/constants";
import { CampaignStatusComponent } from "@app/core/campaign-status-label/campaign-status-label.component";
import { ClickableComponent } from "@app/core/clickable";
import { FeaturedFilterLabel } from "@app/core/featured-filter-label/featured-filter-label";
import { ImageComponent } from "@app/core/image";
import { Icon } from "@app/components/icon/icon";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";

import styles from "./merchant-card-v3.module.scss";

export interface IMerchantCardV3Props {
  merchant: IOutFeaturedMerchantDTO;
  onClick?: () => void;
  platformId: number;
  responsive?: boolean;
  viewType?: number;
  variant?: "normal" | "mobile-search";
}

const component = (props: IMerchantCardV3Props) => {
  const {
    viewType = 100,
    merchant,
    responsive,
    merchant: { campaign, slug }
  } = props;

  const urlAddition = formatMessage({ id: "global.urlAddition" });
  const featuredFilter = campaign?.featuredFilter;
  const outMerchantLink = urlAddition ? `/out/store/${urlAddition}/${slug}/` : `/out/store/${slug}/`;
  const internalMerchantLink = `${formatMessage({ id: "global.merchantOverviewLink" })}${slug}/`;
  const cardBorderStyles = clsx(
    (props.variant === "normal" || !props.variant) && styles.normal,
    props.variant === "mobile-search" && styles.mobileSearch
  );

  return (
    <div className={clsx(styles.merchantCard, (responsive || viewType < 2) && styles.responsive, cardBorderStyles)}>
      <a className={styles.header} href={outMerchantLink} rel={RelAttributes.SPONSORED} target="_blank">
        <div className={styles.campaignStatus}>
          <CampaignStatusComponent durationStatus={campaign?.durationStatus} />
        </div>
        {featuredFilter?.name && (
          <div className={styles.featuredLabel}>
            <FeaturedFilterLabel
              className={styles["label"]}
              label={featuredFilter?.label}
              text={featuredFilter?.name}
              corners="right"
            />
          </div>
        )}
        <div className={styles.image}>
          <ImageComponent
            alt={merchant.logoImage?.alt || ""}
            src={getOptimizedImageUrl({ url: merchant.logoImage?.url, height: 120, quality: 100 })}
            title={merchant.logoImage?.title || ""}
            forceImageTag
            isBlocking
          />
        </div>
      </a>
      <div className={styles.content}>
        {merchant.name && (
          <Link onClick={props.onClick} className={styles.merchant} to={internalMerchantLink}>
            {merchant.name}
          </Link>
        )}
        <a
          className={clsx(styles.description, !campaign?.excerpt && styles.emptyDescription)}
          href={outMerchantLink}
          rel="sponsored"
          target="_blank"
        >
          {campaign?.excerpt}
        </a>
        <div className={styles.button}>
          <ClickableComponent
            variant="primary-new"
            iconStyle="filled"
            title={formatMessage({ id: "merchantCard.button.toCampaignPageOut" })}
            iconRight={IcnChevronRight}
            href={outMerchantLink}
            fullWidth
            forceExternal
            target="_blank"
            relAttribute={RelAttributes.SPONSORED}
          />
        </div>
        <a className={styles.outMerchantLink} target="_blank" rel="sponsored" href={outMerchantLink}>
          <span className={styles.merchantLinkText}>{formatMessage({ id: "dealCard.showAllMerchantDeals" })}</span>
          <Icon icon={IcnChevronRight} fillColor="#999999" size="9px" />
        </a>
      </div>
    </div>
  );
};

const MerchantCardV3 = React.memo(component);
export { MerchantCardV3 };
