import * as express from "express";
import { ThunkDispatch } from "redux-thunk";

import { IBasePageInformation } from "@app/api/wp-page-fetcher/utils/set-base-page-information";
import Logger from "@app/util/logger";
import { IBaseModules } from "@app/redux/reducers/page";
import { formatMessage } from "@app/translations/intl";

import { JsonSchemaModule } from "./../modules/json-schema/json-schema.module";
import { PageModel } from "../pagebuilder/page.model";
import { urlSplitter } from "./utils/url-splitter";
import { HeaderInfoModule } from "../modules/header-info/header-info.module";
import { moduleSelector } from "./module-selector/module-selector";
import { postsWordPressApiGet } from "./wordpress-api/base-wp-api";
import { WordPressPostModule } from "../modules/wordpress-module/wordpress-module";
import { checkSpecialSinglePage } from "./utils/check-special-single-page";
import { MetaData, MetaDataRobots } from "../pagebuilder/meta-data";
import { IWPPageMeta, IWPPageMetaHrefLang } from "./module-selector/main-wp.interface";
import { BlogSingleHeaderModule } from "../modules/blog-single-header/blog-single-header";
import { HomepageHeader } from "../modules/homepage-header/homepage-header.module";
import { MediumHeroModule } from "../modules/medium-hero/medium-hero";
import { ShopSingleHeaderModule } from "../modules/shop-single-header/shop-single-header.module";
import { ProductSingleModule } from "../modules/product-single/product-single.module";

export interface IPageFetcherProps extends IBasePageInformation {
  baseModules?: IBaseModules;
  dispatch?: ThunkDispatch<any, any, any>;
  pageUrl: string;
  res?: express.Response;
  splittedUrl?: string[];
}

export interface ISpecialPageMetaData {
  description?: string;
  hrefLang?: string;
  image?: string;
  robots?: MetaDataRobots;
  title?: string;
  jsonSchema?: string;
}

export const wpPageFetcher = async (props: IPageFetcherProps): Promise<PageModel | 302> => {
  const splittedUrl = urlSplitter(props.pageUrl);

  return await returnWPPage({ ...props, splittedUrl });
};

const returnWPPage = async (props: IPageFetcherProps): Promise<PageModel | 302> => {
  const { errorPages, pageUrl, res, platformId, shopPageUrlName, splittedUrl, productPageUrlName } = props;

  try {
    const fixedUrl = fixWPUrl(pageUrl, shopPageUrlName, splittedUrl, productPageUrlName);
    const wpPage = await postsWordPressApiGet(platformId, fixedUrl);

    if (wpPage === 404) return pageNotFound(await errorPages.notFoundPage(), pageUrl, res);

    if (wpPage === 503 || !wpPage || wpPage.length === 0) {
      return pageNotAvailable(await errorPages.unavailablePage(), pageUrl, res);
    }

    const moduleList = await moduleSelector({ ...props, pageData: wpPage[0] });
    const merchantSingleHeaderModule = moduleList?.find(module => module?.name === "ShopSingleHeaderModule") as
      | ShopSingleHeaderModule
      | undefined;
    const productSingleModule = moduleList?.find(module => module?.name === "ProductSingleModule") as
      | ProductSingleModule
      | undefined;
    const checkModuleOffline = moduleList?.find(module => module.status === 302);

    if (checkModuleOffline) {
      return 302;
    }

    if (!merchantSingleHeaderModule && fixedUrl === `/${shopPageUrlName}/single/`)
      return pageNotFound(await errorPages.notFoundPage(), pageUrl, res);

    if (!productSingleModule && fixedUrl === `/${productPageUrlName}/single/`)
      return pageNotFound(await errorPages.notFoundPage(), pageUrl, res);

    if (!moduleList || moduleList.length === 0)
      return pageNotAvailable(await errorPages.unavailablePage(), pageUrl, res);

    const [page] = wpPage;

    return {
      id: `${page.data?.post?.ID}`,
      pageRes: 200,
      metaData: getPageMeta(
        page.meta,
        specialPageMetaData(
          splittedUrl,
          platformId,
          shopPageUrlName,
          productPageUrlName,
          merchantSingleHeaderModule,
          productSingleModule
        ),
        moduleList
      ),
      route: pageUrl,
      wordPressPostModules: moduleList
    };
  } catch (e) {
    Logger.logError(e, "Error in: returnWPPage");

    return pageNotAvailable(await errorPages.unavailablePage(), pageUrl, res);
  }
};

export const specialPageMetaData = (
  splittedUrl?: string[],
  platformId?: number,
  shopPageUrlName?: string,
  productPageUrlName?: string,
  merchantSingleHeaderModule?: ShopSingleHeaderModule,
  productSingleModule?: ProductSingleModule
): ISpecialPageMetaData | undefined => {
  if (!splittedUrl || !platformId) return undefined;
  const isMerchantSinglePage = checkSpecialSinglePage(shopPageUrlName, splittedUrl);
  const isProductSinglePage = checkSpecialSinglePage(productPageUrlName, splittedUrl);

  if (isMerchantSinglePage && merchantSingleHeaderModule) {
    return {
      title: merchantSingleHeaderModule.seo?.title,
      description: merchantSingleHeaderModule.seo?.description,
      image: merchantSingleHeaderModule.merchant?.logoImage?.url || merchantSingleHeaderModule.fallbackHeaderImage?.src,
      hrefLang: merchantSingleHeaderModule.seo?.alternateHTML,
      jsonSchema: merchantSingleHeaderModule.seo?.jsonSchema,
      // 0 0 means index,follow
      robots: { follow: 0, index: 0 }
    };
  }

  if (isProductSinglePage && productSingleModule) {
    return {
      title: productSingleModule.product.seo?.title,
      description: productSingleModule.product?.seo?.description,
      image: productSingleModule.product.image?.url || formatMessage({ id: "fallbackHeader.src" }),
      hrefLang: productSingleModule.product?.seo?.alternateHTML,
      jsonSchema: productSingleModule.product?.seo?.jsonSchema,
      // 0 0 means index,follow
      robots: { follow: 0, index: 0 }
    };
  }
};

const getPageMeta = (
  metaData?: IWPPageMeta,
  specialPageMeta?: ISpecialPageMetaData,
  moduleList?: WordPressPostModule[]
): MetaData => {
  const jsonSchemaModule = moduleList?.find(module => module.name === "jsonSchemaModule") as JsonSchemaModule;

  const metaTitle = specialPageMeta?.title || metaData?.seoTitle || "";
  const metaDescription = specialPageMeta?.description || metaData?.seoMetaDescription || "";
  const ogTitle = specialPageMeta?.title || metaData?.facebookTitle || metaTitle;
  const ogDescription = specialPageMeta?.description || metaData?.facebookDescription || metaDescription;
  const ogImage =
    specialPageMeta?.image ||
    metaData?.facebookImage ||
    metaData?.featuredImage ||
    getOgImageInHeaderModules(moduleList);
  const hrefLang = specialPageMeta?.hrefLang || getHrefLangs(metaData?.hreflang);
  const robots = {
    index: specialPageMeta?.robots?.index ?? metaData?.metaRobotIndex,
    follow:
      specialPageMeta?.robots?.follow || specialPageMeta?.robots?.follow === 0
        ? specialPageMeta.robots.follow
        : metaData?.metaRobotFollow,
    advanced: metaData?.metaRobotAdvanced
  };
  const jsonSchema = specialPageMeta?.jsonSchema || jsonSchemaModule?.jsonSchema;

  return {
    description: metaDescription,
    title: metaTitle,
    hrefLang: hrefLang,
    og: {
      title: ogTitle,
      description: ogDescription,
      image: ogImage
    },
    canonicalUrl: metaData?.canonicalUrl,
    twitter: {
      title: metaData?.twitterTitle,
      description: metaData?.twitterDescription,
      image: metaData?.twitterImage
    },
    jsonSchema,
    robots
  };
};

const getHrefLangs = (hrefLang?: IWPPageMetaHrefLang[]): string => {
  if (!hrefLang) return "";

  const mappedHrefs = hrefLang.map(href => {
    if (!href.value || !href.lang) return undefined;

    const hrefLang = href.lang.replace("_", "-").toLowerCase();

    return `<link rel="alternate" href="${href.value}" hreflang="${hrefLang}">`;
  });

  const hrefsToString = mappedHrefs.filter(mappedHref => mappedHref).join("");

  return hrefsToString;
};

const pageNotAvailable = (notAvailablePage: PageModel, pageUrl: string, res?: express.Response) => {
  Logger.logMessage(`Page is incomplete: ${pageUrl}`);
  if (res) {
    res.status(503);
  }
  return { ...notAvailablePage, wordPressPostModules: notAvailablePage.wordPressPostModules.filter(Boolean) };
};

const pageNotFound = (notFoundPage: PageModel, pageUrl: string, res?: express.Response) => {
  Logger.logMessage(`Page not found: ${pageUrl}`);

  if (res) {
    res.status(404);
  }
  return { ...notFoundPage, wordPressPostModules: notFoundPage.wordPressPostModules.filter(Boolean) };
};

const fixWPUrl = (
  pageUrl: string,
  shopPageUrlName: string,
  splittedUrl?: string[],
  productPageUrlName?: string
): string | undefined => {
  const merchantSinglePage = `/${shopPageUrlName}/single/`;
  const productSinglePage = `/${productPageUrlName}/single/`;

  if (!splittedUrl) return pageUrl;

  if (pageUrl === merchantSinglePage || pageUrl === productSinglePage) return undefined;

  if (checkSpecialSinglePage(shopPageUrlName, splittedUrl)) {
    return merchantSinglePage;
  }

  if (checkSpecialSinglePage(productPageUrlName, splittedUrl)) {
    return productSinglePage;
  }

  const urlAddition = formatMessage({ id: "global.urlAddition" });

  if (splittedUrl[0] && splittedUrl[0].toLowerCase() === urlAddition) {
    splittedUrl.shift();
    const urlFromSplit = splittedUrl.join("/");

    return splittedUrl.length > 0 ? `/${urlFromSplit}/` : "/";
  }

  return pageUrl;
};

const getOgImageInHeaderModules = (modules?: WordPressPostModule[]): string => {
  if (!modules) return "";

  let image;

  modules.forEach(module => {
    switch (module.name) {
      case "HeaderInfo":
        const headerInfoModule = module as HeaderInfoModule;
        image = headerInfoModule.image?.src;
        break;
      case "BlogSingleHeaderModule":
        const blogSingleHeaderModule = module as BlogSingleHeaderModule;
        image = blogSingleHeaderModule.image?.src;
        break;
      case "HomepageHeader":
        const headerHomepage = module as HomepageHeader;
        image = headerHomepage.slides[0].image;
        break;
      case "MediumHeroModule":
        const headerSmallImage = module as MediumHeroModule;
        image = headerSmallImage.image?.src;
        break;
      case "ShopSingleHeaderModule":
        const shopSingleHeaderModule = module as ShopSingleHeaderModule;
        image = shopSingleHeaderModule.fallbackHeaderImage?.src;
        break;
      default:
        return "";
    }
  });

  return image || "";
};
